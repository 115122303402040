// import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { supabase } from '$lib/supabase';
import { supabaseIntegration } from '@supabase/sentry-js-integration';


Sentry.init({
  dsn: 'https://6e3d266dd1d9d6eeb8f63c4e2a796689@o4506090172448768.ingest.sentry.io/4506090173956096',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    Sentry.replayIntegration(),
    supabaseIntegration(supabase, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
  ],
  enabled: import.meta.env.PROD,
  environment: import.meta.env.VITE_PROJECT_MODE,
  ignoreTransactions: ["api/mb-webhook"],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
