import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [];

export const dictionary = {
		"/": [~5],
		"/app": [6,[2]],
		"/app/event": [7,[2]],
		"/app/event/[id]": [8,[2]],
		"/app/event/[id]/coach": [9,[2]],
		"/app/event/[id]/manage": [10,[2,3]],
		"/app/event/[id]/manage/athletes": [11,[2,3]],
		"/app/event/[id]/manage/athletes/addathlete": [13,[2,3]],
		"/app/event/[id]/manage/athletes/createathlete": [14,[2,3]],
		"/app/event/[id]/manage/athletes/[eventathleteid]": [12,[2,3]],
		"/app/event/[id]/manage/performance": [15,[2,3]],
		"/app/event/[id]/manage/setup": [16,[2,3]],
		"/app/event/[id]/manage/timeslot": [17,[2,3]],
		"/app/event/[id]/manage/timeslot/[eventtimeslotid]": [18,[2,3]],
		"/app/settings": [19,[2]],
		"/app/settings/setup/attributes": [20,[2,4]],
		"/app/settings/setup/attributes/[id]": [21,[2,4]],
		"/app/settings/setup/equipment": [22,[2,4]],
		"/app/settings/setup/equipment/[id]": [23,[2,4]],
		"/app/settings/setup/events": [24,[2,4]],
		"/app/settings/setup/events/import": [26,[2,4]],
		"/app/settings/setup/events/[id]": [25,[2,4]],
		"/app/settings/setup/sites": [27,[2,4]],
		"/app/settings/setup/sites/[id]": [28,[2,4]],
		"/app/settings/setup/tests": [29,[2,4]],
		"/app/settings/setup/tests/groups": [31,[2,4]],
		"/app/settings/setup/tests/groups/[testgroupid]": [32,[2,4]],
		"/app/settings/setup/tests/[id]": [30,[2,4]],
		"/app/settings/setup/units": [33,[2,4]],
		"/app/settings/setup/units/[id]": [34,[2,4]],
		"/app/settings/setup/users": [35,[2,4]],
		"/app/settings/setup/users/[siteid]/[id]": [36,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';